<template>
    <v-expand-transition>
        <v-card>
            <v-data-table
                calculate-widths
                :headers="headers"
                :items="courses"
                :loading="loading"
                class="poppins"
                :hide-default-footer="selected !== 'Total Users per Course'"
                :footer-props="{
                    'items-per-page-options': itemsPerPageOptions
                }"
                :page="page"
                :server-items-length="totalCount"
                @pagination="(e) => {
                    page = e.page
                    paginate = String(e.itemsPerPage),
                    getCourses()
                }"
                >
                <template v-slot:item.title="{ item }">
                    <span @click="item.enrollments_count > 0 && $emit('viewList', { filter: selected, value: selected === 'Total Users per Course' ? item.id : item.title})" text class="roboto text-capitalize pa-2 text-right ma-0 primary--text cursor-pointer" :style="'letter-spacing: normal; justify-content: flex-start;'">
                        {{ item.title }}
                    </span>
                </template>
                <template v-slot:item.status="{ item }">
                    {{ item.status }}ED
                </template>
            </v-data-table>
            <!-- <FormPagination 
                v-if="selected == 'Total Users per Course'"
                :pageCount="pageCount" 
                :page="page"
                :paginate="paginate"
                @page="(e) => { page = e, getCourses() }" 
                @paginate="(e) => { paginate = e, page = 1, getCourses() }"/> -->
        </v-card>
    </v-expand-transition>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
export default {
    props: ['search', 'selected'],
    data: () => ({
        headers: [ 
            { text: "COURSE", value: "title", width: '25%', sortable: false},
            { text: "INSTRUCTOR", value: "instructor", align: 'center', sortable: false },
            { text: "ENROLLED USERS", value: "enrollments_count", align: 'center', sortable: false },
            { text: "STATUS", value: "status", align: 'center', sortable: false},
        ],
        paginate: '10',
        page: 1,
        pageCount: 1,
        totalCount: 0,
        loading: false,
        itemsPerPageOptions: [5, 10, 20, 30, 40, 50],
    }),
    methods: {
        ...mapActions('admin', ['getCoursesUserCount']),
        ...mapMutations(['alertMutation']),

        getCourses(){
            if(!this.loading) {
                this.loading = true
                this.getCoursesUserCount({ filter: this.selected, page: this.page, paginate: Number(this.paginate) }).then(res => {
                    this.loading = false
                    if(this.selected !== 'Total Users per Course') {
                        this.headers = [ 
                            { text: this.selected, value: "title", sortable: false },
                            { text: "No. of users", value: "users_count", align: 'center', sortable: false },
                        ]
                    } else {
                        this.headers = [ 
                            { text: "COURSE", value: "title", width: '25%', sortable: false },
                            { text: "INSTRUCTOR", value: "uploader.full_name", align: 'center', sortable: false },
                            { text: "ENROLLED USERS", value: "enrollments_count", align: 'center', sortable: false },
                            { text: "STATUS", value: "status", align: 'center', sortable: false },
                        ]
                        this.pageCount = res.last_page
                        this.totalCount = res.total
                        this.page = res.current_page
                    }
                }).catch(e => {
                    this.loading = false
                    this.alertMutation({
                        show: true,
                        text: 'Something went wrong',
                        type: "error"
                    })
                })
            }
        }
    },
    created() {
        this.getCourses()
    },
    computed: {
        ...mapState('admin', {
            courses: (state) => state.courses
        }),

        itemsPerPage(){
            return Number(this.paginate)
        }
    },
    watch: {
        selected(val) {
            this.getCourses()
        }
    }
}
</script>