var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expand-transition',[_c('v-card',[_c('v-data-table',{staticClass:"poppins",attrs:{"calculate-widths":"","headers":_vm.headers,"items":_vm.courses,"loading":_vm.loading,"hide-default-footer":_vm.selected !== 'Total Users per Course',"footer-props":{
                'items-per-page-options': _vm.itemsPerPageOptions
            },"page":_vm.page,"server-items-length":_vm.totalCount},on:{"pagination":(e) => {
                _vm.page = e.page
                _vm.paginate = String(e.itemsPerPage),
                _vm.getCourses()
            }},scopedSlots:_vm._u([{key:"item.title",fn:function({ item }){return [_c('span',{staticClass:"roboto text-capitalize pa-2 text-right ma-0 primary--text cursor-pointer",style:('letter-spacing: normal; justify-content: flex-start;'),attrs:{"text":""},on:{"click":function($event){item.enrollments_count > 0 && _vm.$emit('viewList', { filter: _vm.selected, value: _vm.selected === 'Total Users per Course' ? item.id : item.title})}}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.status",fn:function({ item }){return [_vm._v(" "+_vm._s(item.status)+"ED ")]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }