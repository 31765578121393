<template>
  <section id="dashboard" class="pb-10">
    <v-row class="mb-5">
      <v-col cols="12" md="3" v-for="(item, i) in items" :key="i">
        <TopCard :item="item"/>
      </v-col>
    </v-row>
    <v-card class="custom-border border pa-10 mb-5">
      <v-select
        :items="filters"
        v-model="selected"
        class="col-12 col-md-3 f14 fw600 poppins no-outline-custom-field">
      </v-select>
      <UserBarGraph  :selected="selected"/>
    </v-card>
    <section v-if="!showUsers" >
      <section class="d-flex flex-wrap justify-space-between">
        <v-text-field
          solo
          dense
          flat
          class="col-xl-3 col-lg-4 custom-border general-custom-field f14 poppins fw500 mb-5"
          hide-details
          prepend-inner-icon="mdi-magnify"
          placeholder="Search"
          v-model="search"
        />
        <!-- <v-select
          :items="filter"
          v-model="selected"
          hide-details
          solo
          dense 
          flat
          class="col-xl-3 col-lg-4 f14 fw500 poppins custom-border general-custom-field mb-5">
        </v-select> -->
      </section>
      
      <CourseTable @viewList="viewList" :search="search" :selected="selected" class="fade"/>
      
    </section>
    <CourseUserTable v-if="showUsers" :items="users" @back="back" :listName="listName" :selected="selected" class="fade"/>
  </section>
</template>

<script>
import TopCard from '../../components/reusable/TopCard.vue'
import UserBarGraph from '../../components/admin/dashboard/UserBarGraph.vue';
import CourseTable from '../../components/admin/dashboard/CourseTable.vue';
import CourseUserTable from '../../components/admin/dashboard/CourseUserTable.vue';

export default {
  components: {
    TopCard,
    UserBarGraph,
    CourseTable,
    CourseUserTable
  },
  created() {
    this.$api.get(`admin/list/cards`).then( res => {
      if(res.data.data) {
        this.items = [
          {
            title: 'Total Enrolled Users',
            count: res.data.data.total_enrolled_users,
            color: '#3F5DD033', 
            outline: '#3F5DD0'
          },
          // {
          //   title: 'Total List of Courses',
          //   count: res.data.data.total_list_of_courses,
          //   color: '#7BC14533',
          //   outline: '#7BC145'
          // },
          {
            title: 'Published Courses',
            count: res.data.data.published_courses,
            color: '#7BC14533',
            outline: '#7BC145'
          },
          {
            title: 'Total Instructors',
            count: res.data.data.total_instructors,
            color: '#E49E3233',
            outline: '#E49E32'
          },
        ]
      }
    })
  },
  data: () => ({
    items: [
      {
        title: 'Total Enrolled Users',
        count: '-',
        color: '#3F5DD033', 
        outline: '#3F5DD0'
      },
      {
        title: 'Total List of Courses',
        count: '-',
        color: '#7BC14533',
        outline: '#7BC145'
      },
      {
        title: 'Published Courses',
        count: '-',
        color: '#E49E3233',
        outline: '#E49E32'
      },
      {
        title: 'Total Instructors',
        count: '-',
        color: '#E49E3233',
        outline: '#E49E32'
      },
    ],
    users: [],
    search: '',
    filters: [ 'Total Users per Course' ],
    selected: 'Total Users per Course',
    showUsers: false,
    listName: ''
  }),
  methods: {
    viewList(e) {
      this.showUsers = true
      this.listName = e.value
      this.$api.get('/admin/course/users', { params: e}).then( res => {
        this.users = res.data.data
      })
    },

    back() {
      this.showUsers = false
      this.users = []
      this.selected = this.selected
    }
  },
  mounted() {
    this.$api.get('/admin/custom-registration/field/lists').then( res => {
        this.filters.push(...res.data.data)
    })
  },
  watch: {
    selected(val) {
      this.showUsers = false
    }
  }
}
</script>