<template>
    <section>
        <v-btn text @click="$emit('back')">
            <v-icon small>
                mdi-arrow-left
            </v-icon>
            BACK
        </v-btn>
        <section class="d-flex flex-wrap justify-space-between mb-3">
            <v-text-field
            solo
            dense
            flat
            class="col-xl-3 col-lg-4 mb-3 custom-border general-custom-field f14 poppins fw500"
            hide-details
            prepend-inner-icon="mdi-magnify"
            placeholder="Search"
            v-model="search"
            />
            
            <v-btn dense outlined color="primary" class="ml-auto" @click="exportTable">
                Export List
            </v-btn>
        </section>
        <v-expand-transition>
            <v-data-table
                :headers="headers"
                :items="items"
                class="elevation-1 poppins"
                :search="search"
                >
                <template v-slot:item.status="{ item }">
                    <v-chip v-if="item.status === 'active'" small dense color="#7BC14533" class="success--text ma-auto">ACTIVE</v-chip>
                    <v-chip v-if="item.status === 'inactive'" small dense color="#BDBDBD33" class="secondary-2--text ma-auto">INACTIVE</v-chip>
                </template>
            </v-data-table>
        </v-expand-transition>
    </section>
</template>

<script>
import * as XLSX from 'xlsx';
export default {
    props: ['items', 'listName', 'selected'],
    data: () => ({
        headers: [ 
            { text: "First Name", value: "first_name"},
            { text: "Middle Name", value: "middle_name", align: 'center' },
            { text: "Last Name", value: "last_name", align: 'center' },
            { text: "Email", value: "email", align: 'center' },
            { text: "Status", value: "status", align: 'center' },
        ],
        search: ''
    }),
    methods: {
        exportTable() {
            let users = []
            this.items.forEach(user => {
                let data = {}
                if(user) {
                    if(user.data) {
                        Object.keys(user.data).forEach(page => {
                            Object.keys(user.data[page]).forEach(key => {
                                data[key.replace(/_/g, " ")] = user.data[page][key]
                            })
                        })
                    }

                    users.push({
                        'first name': user.first_name,
                        'middle name': user.middle_name,
                        'last name': user.last_name,
                        'email': user.email,
                        'role': user.role,
                        ...data
                    })
                }
            })
            const worksheet = XLSX.utils.json_to_sheet(users)
            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, worksheet, ``)
            //const max_width = _student_data.reduce((w, r) => Math.max(w, r['Name'].length + 2), 10);
            //worksheet["!cols"] = [ { wch: max_width } ];
            //worksheet["B1"]["!cols"] = [ { wch: 100 } ]
            XLSX.writeFile(wb,`Analytics: ${this.selected} - ${this.listName}.xlsx`)
        }
    }
}

</script>